import * as React from 'react';
import * as _ from 'lodash';
import { AreaNameType } from '../../../../lib/interfaces/search';
import { searchLink } from '../../../../lib/utils/link';
import { PrefectureSearchContext } from '../SearchContext';

type Props = {
  isShow: boolean;
}

const PrefectureModalContent: React.FC<Props> = ({ isShow }) => {
  const [showModalName, setModalName] = React.useState<AreaNameType | undefined>();
  const { occupationAscii, prefectures, counter, searchParams, normalizedUrlParams } = React.useContext(PrefectureSearchContext);

  React.useEffect(() => {
    if (window.parent.screen.width <= 600) {
      if (showModalName) {
        document.body.style.cssText = 'overflow: hidden;';
      } else {
        document.body.style.cssText = '';
      }
    }
  }, [showModalName]);

  return (
    <div className={`search_index-view ${isShow ? 'search_index-view-active' : ''}`}>
      <div className="c-overlay search_index-modal-overlay js-overlay" style={showModalName ? { display: 'block' } : {}} onClick={() => setModalName(undefined)} />
      <ul className="search_index-view--area_list">
        {prefectures.map((optionPrefecture, i) => (
          <li key={i} className="search_index-view--area_list--item">
            <a className="search_index-view--area_list--item--link" onClick={() => setModalName(optionPrefecture.area_name)}>{optionPrefecture.area_name}</a>
            <div className={`search_index-modal ${showModalName === optionPrefecture.area_name ? 'search_index-modal-show' : ''}`}>
              <div className="search_index-modal-frame">
                <div className="search_index-modal-body">
                  <div className="search_index-modal-head forSp">都道府県から選択</div>
                  <a aria-label="閉じる" className="forSp search_index-modal-close_btn" onClick={() => setModalName(undefined)}>×</a>
                  <div className="search_index-view--area_list--item--body">
                    <ul className="search_index-view--area_list--item--pref_list">
                      {optionPrefecture.prefectures.map((prefecture, j) => (
                        <li
                          key={j}
                          className={`search_index-view--area_list--item--pref_list--item ${!counter || _.get(counter, ['prefectures', prefecture?.code]) ? '' : 'm-disabled'}`}
                        >
                          <a
                            aria-label={prefecture?.name}
                            href={
                              searchLink(
                                occupationAscii,
                                Object.assign({ prefecture_code: prefecture?.code }, _.omit(searchParams, ['occupation_ascii'])),
                                Object.assign({ occupation_ascii: normalizedUrlParams['occupation_ascii'],  prefecture_ascii: prefecture?.ascii }, normalizedUrlParams)
                              )
                            }
                          >
                            <span className="search_index-view--area_list--item--pref_list--item-title">{prefecture?.name}</span>
                            {counter && (
                              <span className="search_index-view--area_list--item--pref_list--item-count">
                                ({_.get(counter, ['prefectures', prefecture?.code]) || 0})
                              </span>
                            )}
                            <span className="search_index-view--area_list--item--pref_list--item-arrow"><i className="fas fa-chevron-right" /></span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
};

export default PrefectureModalContent;
